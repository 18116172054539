<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-section-heading
        title="Properties"
      >
        Please click the link below to visit our properties page.
      </base-section-heading>

      <router-link
        to="/Properties"
        class=" text-h5 font-weight-bold teal--text"
      >
        Properties
      </router-link>

      <div class="py-4" />
      <base-section-heading
        title="Rental Application "
      >
        Please read  the steps below to submit a rental application.<br>
        1. Download a PDF copy of the rental application by clicking
        <a
          href="/application.pdf"
        >
          here
        </a> <br>
        2. Fill out the form but leave the SSN empty.<br>
        3. Email the form back to accessplusproperties@gmail.com
      </base-section-heading>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'Properties',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style type="css" scoped>
  #social {
  background-color: darkslateblue !important;
}
  .whitetext{
    background-color: darkslateblue !important;
    color: white !important;
}
</style>
